<script>
export default {
  metaInfo: {
    title: 'Отзывы о ХаниМани, обзоры домашней бухгалтерии ХаниМани',
    meta: [
      { name: 'description', content: 'Отзывы о ХаниМани' }
    ]
  }
}
</script>

<template lang="pug">
.testimonials_page
  section.hero.is-info
    .hero-body
      .container.content
        .title.is-2 Любимые пользователи о любимой ХаниМани
  section.section.testimonials
    .container.content
      .columns
        .column.is-10.is-offset-1
          .has-text-centered
            .title.is-3 География пользователей ХаниМани
            .subtitle.is-5 Африка и Антарктида ещё пока не за-ханимани-лись :)
            img.il_frame(width='600', src='./assets/about/testimonials_map.jpg')
          .testimonial-container.right.testimonial-blue
            blockquote.testimonial
              p
                | ХаниМани реально
                strong  разгружает голову
                | :
                br
                | один раз сел, подумал, занес предполагаемые доходы и планируемые периодические траты — и все, можно быть спокойным за семейный бюджет! Остается только следить, чтоб реальные траты не превышали те, что внес в систему, а доходы были не меньше (а лучше — больше), чем запланировал.
              p
                | Вторая крутая фишка — это цели-накопления. Планируешь в обозримом будущем какую-либо финансовую цель (новый телефон, телевизор, поездку на Таити и т.д.), а система сама начинает потихонечку накапливать на нее деньги. Когда наступает требуемая дата,
                strong  спокойно тратишь деньги, никаким образом не затрагивая семейный бюджет
                | .
              p
                | Мы, например, таким образом сформировали сначала «финансовую подушку» — резервный фонд (который, впрочем, был потрачен во время строительства нашего нового дома, теперь копим заново :) ), а потом периодически планировали разные небольшие, но приятные покупки: новое автокресло ребенку, качели в наш сад, летнюю резину для автомобиля, 100 роз теще на 50-летие :)
            .testimonial-arrow-down
            p.testimonial-author
              | Юрий
              span  (почти 2 года с ХаниМани)
          .testimonial-container.left.testimonial-green
            blockquote.testimonial
              p
                | Уже больше года, вместе с супругой, используем ХаниМани. До этого перепробовали с десяток различных систем для ведения домашней бухгалтерии, но ни одна из них в итоге не прижилась. ХаниМани сочетает в себе простоту блокнота для записи ежедневных трат с функциональностью системы для долгосрочного планирования бюджета. Интерфейс дружелюбен и интуитивен настолько, насколько это вообще возможно. ХаниМани это удобный инструмент который позволяет вам избавиться от лишней головной боли и превращает надоевшую рутину домашней бухгалтерии в приносящий радость процесс
            .testimonial-arrow-down
            p.testimonial-author
              | Владимир
              span  (Всеволожск)
          .testimonial-container.right.testimonial-yellow
            blockquote.testimonial
              h2 Нравится:
              ul
                li
                  strong  Дружелюбный интерфейс и приятный дизайн
                  | . В первую очередь всегда смотрю на интерфейс. Перепробовав около десятка систем домашней бухгалтерии, остановился на ХаниМани: все просто и понятно, и выглядит очень симпатично.
                li
                  | Тут
                  strong  нет большого количества ненужных функций
                  | . В других системах хоть и было больше возможностей, но они мне не нужны, а чрезмерное количество функций усложняет интерфейс.
                li
                  strong  Простое и понятное планирование расходов
                  | . То есть в других системах, чтобы запланировать расход, мне нужно было нажать на кнопку «добавить расход», потом из списка в маленьком календаре выбрать нужную дату, ввести расход и т.п. А тут просто щелкнул в большом календаре на дату и добавил расход. Всего на несколько кликов меньше, но очень удобно. То же самое и с системой выбора категорий расходов — удобней, чем у остальных.
                li
                  strong  Цена
                  | . С самого начала решил, что больше 3-5$ в месяц не готов платить за систему.
                li
                  | И еще
                  strong  много приятных мелочей
                  |  (цветовая гамма элементов интерфейса и т.д.), из которых складывается впечатление от системы.
              p
                | В целом, понравилось что значительное внимание уделили проектированию интерфейса. Спасибо за такую хорошую систему домашней бухгалтерии, с которой приятно работать :-)
            .testimonial-arrow-down
            p.testimonial-author
              | Алексей
              span  (Коломна)
          .testimonial-container.left.testimonial-blue
            blockquote.testimonial
              h2 Сервис шикарен!
              ul
                li
                  strong  Удобный интерфейс
                  |  (очень большой плюс у ХаниМани, интерфейс — просто супер! Красивый, ненагроможденный и нет никаких раздражающих зрение элементов)
                li
                  strong  Удобство
                  |  ведения и отображения расходов/доходов
                li
                  | Запись и планирование
                  strong  прямо в календаре
                li Просмотр итогов по категориям/подкатегориям
                li
                  | Возможность
                  strong  планирования на лету
                  |  (не переходя в отдельные разделы, где уже не видно текущих «Реальных цифр»).
                li
                  strong  Drag&drop по календарю
                  |  не открывая записи (очень сокращает время ввода и переноса планов — это частое действие)
                li
                  | Конверты — оказалось очень удобно,
                  strong  не надо самому считать, хватит ли денег или нет
                li
                  | Не нужно первичное заполнение категорий, поначалу я даже в ступор вошел, типа: «Где категории заполнять?», в общем благотворный «разрыв шаблона»
            .testimonial-arrow-down
            p.testimonial-author
              | Евгений
              span  (Новосибирск)
          .testimonial-container.right.testimonial-orange
            blockquote.testimonial
              p
                | Сервис ХаниМани очень понравился. Оформление и функциональность просто супер. Всегда думал начать вести личный бюджет, но из-за рутинности этого занятия, все время откладывал этот процесс. ХаниМани в этом плане просто спас.
                strong  И интересно, и полезно
                | . Большое спасибо за данный сайт
            .testimonial-arrow-down
            p.testimonial-author
              | Глеб
              span  (Бийск)
          .testimonial-container.left.testimonial-yellow
            blockquote.testimonial
              p
                | ХаниМани позволяет получить всю необходимую и просто интересную мне информацию о моем бюджете, прилагая минимум усилий, да и те усилия, что требуются, скорее приятны, поскольку работает программа очень четко и отлаженно.
              p
                | Помимо того, что я
                strong  знаю теперь, куда уходят деньги, я еще и вижу картину в целом
                |  — какой уровень доходов мне необходим, какой — желателен, какой — совершенно неприемлем.
              p
                | Я сейчас ищу новую работу, и тема заработной платы, которую я намерена запросить, очень важна. Ваша система помогает сориентироваться и решить, какой доход меня устроит. И не только решить, но и аргументировать потенциальному работодателю при необходимости. Спасибо!
            .testimonial-arrow-down
            p.testimonial-author
              | Анна
              span  (Томск)

          .testimonial-container.right.testimonial-green
            blockquote.testimonial
              p ХаниМанюшка мне очень помогает! Особенно, если учесть, что доход у меня всегда разный, сложно предсказуемый. К концу года сумма может быть в разы больше, а в начале вообще ничего. При таком положении вещей сложно просчитать сколько можно потратить, а с ХаниМани без проблем. И копить проще, система накопления в учет не берет, поэтому и желания потратить их нет, какая сумма высвечивается — в ту и стараюсь влезть!
              p Спасибо, лично мне ХаниМани пользоваться очень удобно.
            .testimonial-arrow-down
            p.testimonial-author
              | Наталья
              span  (Новосибирск, 4 года с ХаниМани)

          .has-text-centered
            img.il_frame(width='700', src='./assets/about/testimonials_heart.png')
          .more_testimonials
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='4')
                h3.review-content-name Отличная программа Хани Мани!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Простота и удобство работы.Наличие вэб-интефейса (в угоду меня это критично), а с этого года и приложение для смартфонов.Возможность понимать домашние финансовые резервы во времени, что позволяет решать различные финансовые вопросы.Возможность автоматического накопления на домашние цели.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p — всё ещё развивается :)- платное использование
                  p.review-content-text
                    | Пользуюсь ХаниМани сейчас близ трёх лет. До этого несколько лет вёл личные финансы (и в экселе, и в других системах учёта) — постоянно что-то не устраивало, и возникало волеизъявление поменять подход. А в ХаниМани практически всё реализовано хорошо, все домашние задачи по управлению финансами я легко решаю, необходимости что-либо менять не возникает.
              .testimonial-arrow-down
              p.testimonial-author
                | Vladimir Semenov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Самый лучший сервис для ведения домашней бухгалтерии!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Шикарный сервис. По моей просьбе было сделано оперативно несколько доработок. Я такого ещё нигде не видел!
                        br
                        br
                        |       Возможность работать с виртуальными счетами, такими, как благотворительность, инвестиции, образование. Гибкую систему работы с такими счетами нашёл только в ХаниМани!
                        br
                        |       Удобные отчёты, можно быстро узнать всё что хочешь за прошлые период.
                        br
                        br
                        |       Красивый интерфейс!
                        br
                        br
                        |       И очень очень скромная стоимость за такое удовольствие!
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Иногда не очень быстро получаю ответы от Поддержки, но это и понятно. Всё это — проект одного человека, поэтому, отношусь к этому с пониманием! Но было бы здорово, если бы ответы были по какому-то принципу, например, в течение такого-то времени вам ответят. Уверен, что в будущем, это будет!
                  p.review-content-text
                    | Я просто влюблён в ХаниМиани, рассказываю об этом сервисе друзьям, рекомендую. Спасибо за удобный, качественный, красивый продукт, который приносит реальную пользу! Прекрасный инструмент для развития привычки контролировать расходы и доходы!
                    br
                    br
                    |     Спасибо за ХаниМани!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Сергей Ястребов', src='https://graph.facebook.com/315919175443438/picture')
                | Сергей Ястребов
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='4')
                h3.review-content-name Хорошо
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p Вся система на ладони, можно скачать всю информацию.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Как то нечего жаловаться.
                  p.review-content-text Не смог найти лучшей альтренативы. Можно пробовать.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Andrey Gurin', src='https://lh3.googleusercontent.com/-Wn7mQtZUHpc/AAAAAAAAAAI/AAAAAAAAADQ/YnluLWQVjtM/photo.jpg?sz=50')
                | Andrey Gurin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Отзыв на систему ХаниМани
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | ХаниМани — удобный сервис для ведения домашней бухгалтерии. Можно быстро (если сам себе не усложнишь) заводить траты, причем правильно их распределять. Можно ограничивать бюджет на те траты, которые не хочется раздувать, например развлечения. За 2016 год добавили отличные отчеты и мобильную версию. В общем, 3 года живу в режиме сбора чеков, отлично помогает экономить.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Уже нет
                  p.review-content-text
                    | Положительные. Сервис интуитивно понятный, обновляемый, разработчик Ильдар — настоящая душа проекта.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Юлия Чигинцева', src='https://graph.facebook.com/1887494464818674/picture')
                | Юлия Чигинцева
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Хороший сервис
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Хороший добротный сервис, быстрый отчет по категориям за выбранный период, много визуализации.
                  p.review-content-text
                    | Хороший добротный сервис, пользуюсь больше года, есть свое приложение для айпада. Конечно мои запросы сможет поддерживать и Эксель, но визуализация удобна и ее много, а стоит сервис не так и дорого.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Alexandr Kornienko', src='https://graph.facebook.com/1845178822384224/picture')
                | Alexandr Kornienko
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Отличный сервис с поддержкой и развитием
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Система отчетов, информативность, поддержка, простота использования, удобный интерфейс
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Серьезных недостатков не вижу
                  p.review-content-text
                    | ХаниМани начал пользоваться два с половиной года назад. Перешел на него с сервиса 4конверта, который к сожалению, перестал поддерживаться и глючил. С ХаниМани таких проблем нет, новые функции добавляются, на вопросы приходят подробные ответы, вводить информацию просто, понятная и гибкая система категоризации трат, реализованы отличные отчеты с использованием категорий, которые как раз недавно обновились и стали еще подробнее. В целом считаю этот сервис весьма удобным, информативным и простым в использовании.
                    br
                    | После некоторых попыток оставил использование функционала отслеживания накоплений. Конкретных минусов или недостающих функций назвать не могу, возможно я недостаточно усилий приложил к попытке разобраться с этим. Использую только документирование трат по факту и отчетность.
              .testimonial-arrow-down
              p.testimonial-author
                | Pavel Zakharov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Отличный сервис
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Давно искал что-то простое и наглядное для ведения домашнего учета.
                        br
                        |       В данном случае система достаточно мягкая, но, вместе с тем удобная. Удалось нормально подойти и к статистике и к бюджетированию.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Не хватает аналитики и эвристики. Та что есть — довольно банальна.
                  p.review-content-text
                    | Был сильно удивлен, когда удалось начать откладывать довольно ощутимые деньги. Понятно сколько у меня есть свободных, сколько нужно отложить и не трогать.
                    br
                    |     Ребята — молодцы, так держать.
              .testimonial-arrow-down
              p.testimonial-author
                | Oleg Kleschuk
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Взгляд рядового обывателя
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Основной плюс для меня — это удобство введения данных. Удобное поле для введения в принципе. Удобно вносить данные именно в те календарный дни, когда были сделаны траты. Удобно присваивать категории и вводить новые. Легкий поиск по тратам и доходам (и если мне важно узнать, сколько было потрачено на конкретную покупку или категорию — можно легко найти и сумму и дату).
                        br
                        |       Есть аналитика по тратам и доходам.
                        br
                        |       Очень удобная работа со счетами (есть наличные, карточки, сбережения и т.д. И все это надо учитывать и не терять. При этом я сама могу определять, какие счета участвуют в бюджете (и видны как доступная ссума), а какие нет.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Лично мне не хватает аналитики по отдельным категориям. Например, посмотреть динамику расходов на автомобиль или продукты, или динамику доходов по конкретной категории.
                        br
                        |       И еще не хватает какого-то варианта внесения данных по расходам не заходя на сайт (что-то вроде расширения для Google например).
                  p.review-content-text
                    | Пользуюсь этой программой уже около 2 лет. До этого пробовала вести расходы в разных бесплатных программах. Но именно ХаниМани меня покорила.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Ирина Тишкина', src='https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50')
                | Ирина Тишкина
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Отличная программа Хани Мани!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Очень нравится программа! Огромным плюсом считаю возможность доступа с любого устройства + наличие мобильной версии. То есть информацию могут заносить несколько человек в удобном режиме и даже одновременно! Прекрасно для семейного бюджета.
                        br
                        |       Удобная система счетов и записи транзакций. Немного замороченная система планирования (резервы), надо вникнуть. Но нестандартно и удобно!
                        br
                        |       Мы с мужем пользуемся уже 8 месяцев — очень довольны.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Не очень удобная система отчетов по категориям (доходов или расходов). Графики абсолютно бесполезны. Я бы предпочла просто список с удобными и быстрыми фильтрами по категории, дате/периоду, счету и т.п. Плюс возможность выгрузить список после фильтра в Excel. А здесь сложно быстро вытащить список расходов по конкретной категории за конкретный период.
                        br
                        |       Повторюсь: довольно замороченная система резервов.
                  p.review-content-text
                    | В целом — отличная программа. И цена более чем себя оправдывает. Буду дальше пользоваться с удовольствием! Если еще и отчеты доработаете — будет СУПЕР!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Maria Sukonkina', src='https://graph.facebook.com/1110312985672538/picture')
                | Maria Sukonkina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Пользуюсь программой с 2014 года.
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Все устраивает! Я знаю теперь сколько у меня денег, сколько я потратил и куда, по статистике можно посмотреть, куда можно было и не тратить, кучу денег можно было сэкономить. Да и на душе спокойно, когда все учтено и лежит по полочкам. Сервис нравится, все просто и понятно, немного вначале привыкнуть. Пользуюсь каждый день.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Когда заполнен практически весь месяц записями, таблица видимо начинает много весить, и работает медленнее. Иногда работает с зависанием на одну две секунды, если обновить браузер, то вроде снова работает быстро.
                  p.review-content-text Впечатления хорошие. Мне нравится.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Евсей Ярошенко', src='https://lh3.googleusercontent.com/-cQ1HocicTmk/AAAAAAAAAAI/AAAAAAAADyA/e3HzpRan7U4/photo.jpg?sz=50')
                | Евсей Ярошенко
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name HoneyMoney — удобный сервис для ведения личного денежного учёта
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | 1. Красивый и приятный интерфейс
                        br
                        |       2. Наличие мобильной версии сайта и приложений для телефонов
                        br
                        |       3. Быстрая и качественная техническая поддержка со стороны разработчика
                        br
                        |       4. Простота в использовании
                        br
                        |       5. Шикарная документация
                        br
                        |       6. Полная анонимность и безопастность
                        br
                        |       7. Постоянное активное развитие проекта, в том числе и на основе пожеланий и комментариев пользователей
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p 1. Отсутствие гибкой системы по работе со счетами
                  p.review-content-text
                    | Начал пользоваться системой с конца 2013 года по рекомендации друга. Очень доволен. Свою роль данный сервис выполняет на отлично! Возможно я консервативен, но искать что-то другое и пробовать я не стал. Мои потребности система удовлетворяет на 90%, хотя я использую всего лишь небольшую часть возможностей сервиса.
                    br
                    br
                    |     ХаниМани сильно человекориентированный продукт, располагает к себе каждого пользователя, всё по-простому, по-домашнему.
                    br
                    |     Вокруг сервиса создана вспомогательная инфраструктура в виде блогов, форума, энциклопедии, которые все вместе нацелены на то, чтобы донести до народа базовые вещи, такие как отслеживание своих расходов и исклюение транжырства", учёт будущих доходов, планирование больших покупок, создание сбережений.
                    br
                    |     Там можно найти друзей, научиться жить по средствам, начать создавать свой капитал.
                    br
                    br
                    |     Всем тем, кто до сих пор не ведёт учёт своих денежных потоков я искренне рекомендую данный продукт.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Inna Kalinkina', src='https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50')
                | Inna Kalinkina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Сервис для тех, кто любит держать свои финансы под контролем
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | — простота и удобство
                        br
                        |       — легкое планирование расходов-доходов
                        br
                        |       — виртуальные счета
                        br
                        |       — статистика
                        br
                        |       — мобильное приложение
                        br
                        |       — приемлемая цена
                        br
                        |       — полное отсутствие рекламы
                        br
                        |       — непрерывное развитие сервиса
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p — пропали с появление мобильного приложения
                  p.review-content-text
                    | Пользуюсь ХаниМани уже больше 2-х лет. Сервис сразу понравился своей простотой и при этом удобностью. Легко вносить траты и доходы, категории при этом создаются автоматически. Есть небольшая, но при этом полезная статистика и графики по доходам-расходам и разнице за различные периоды. Управление долгами и кошельками с различными валютами ни чем не отличается от обычных действий.
                    br
                    |     Наличие виртуальных денег позволяет легко планировать траты, в том числе по различным категориям. При этом автоматически рассчитывается количество свободных (незапланированных на траты) денег, даже с учетом будущих доходов. Помогает в случаях затягивания поясов не допустить ситуации отсутствия денег на критичные расходы.
                    br
                    |     Создатели сервиса отзывчивы к пользователям, быстро учли деноминацию в Беларуси. И в целом, сервис развивается и не стоит на месте. Недавно появилось очень удобное мобильное приложение.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Evgeniy Myslovets', src='https://lh4.googleusercontent.com/-vqPeDAAiABY/AAAAAAAAAAI/AAAAAAAAAPM/r4MMcFVj2KU/photo.jpg?sz=50')
                | Evgeniy Myslovets
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Сервис, с которым хочется дружить
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | ХаниМани прост в использовании, но при этом не теряет гибкости. Любые цели, траты, накопления.
                        br
                        |       Очень продуманная политика. Нет автоматической привязки к картам, то есть ни одна операция не пройдет мимо вас. Кроме того, это помогает четко и ясно понять, что и куда уходит из вашего кошелька. Минимум необходимой личной информации, не придется делиться счетами, адресами, явками и паролями.
                        br
                        |       Для скептиков есть бесплатный месяц с полным функционалом.
                        br
                        |       Отзывчивость на потребностям пользователей. Считаете, что что-то нужно добавить? Вас всегда рады выслушать.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Не обнаружено.
                  p.review-content-text
                    | Пользуюсь сервисом несколько лет, не пожалела ни о времени, ни о потраченных деньгах. Сервис очень приятный и удобный. Очень понравилось отношение к пользовательской информации, не надо привязывать карты и счета. Все операции под контролем. Есть необходимые инструменты: создание целей, регулярные платежи, пользовательские категории трат/доходов, накопления. Наглядные регулируемые графики.
                    br
                    |     Кроме того, очень теплый дизайн:)
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Анна Бухтина', src='https://lh3.googleusercontent.com/-oKkqpC3RN7M/AAAAAAAAAAI/AAAAAAAAIbw/F-acm0mZyPA/photo.jpg?sz=50')
                | Анна Бухтина
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Не только контроль, но и планирование
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | — красивый, удобный интерфейс
                        br
                        |       — развивается
                        br
                        |       — есть android клиент (возможно и iPhone — не уточнял)
                        br
                        |       — любящий своё дело разработчик
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | — всё ещё развивается :)
                        br
                        |       — платное использование
                  p.review-content-text
                    | Главной функцией обычных систем контроля личных финансов является отображение распределения расходов/доходов по различным типам. Но лично мне эта функция видится достаточно бесполезной. Главное — это общее планирование бюджетов и сбережений, и в этом вопросе ХаниМани является наиболее продуманной системой. Благодаря ХаниМани я могу распланировать как, куда и сколько я смогу отложить денег. Плюс ко всему — я использую данную систему как напоминатель о регулярных расходах.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Ivan Kutsenka', src='https://lh6.googleusercontent.com/-oAMl6cDCIKs/AAAAAAAAAAI/AAAAAAAAFYc/Kk2p4mXKYmc/photo.jpg?sz=50')
                | Ivan Kutsenka
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Лучший сервис для личной бухгалтерии
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Понятный, удобный, легок в освоении. Виртуальные счета — главное преимущество по сравнению с аналогичными сервисами
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | В мобильном приложении не хватает офлайн-режима, из-за этого я так и не могу им пользоваться на регулярной основе, записываю траты в блокнот, а потом переношу их в десктоп-версию.
                  p.review-content-text
                    | ХаниМани для меня — must have сервис, я уже не представляю ведение своих задач без него. Поначалу нужно привыкнуть, и важно просто регулярно вносить траты даже если надоело/скучно/забывается/бесит, а когда видишь первую статистику, как будто открываются глаза: сколько ты тратишь на разные категории, сколько денег у тебя РЕАЛЬНО в наличии (у ХМ главное преимущество — виртуальные кучки, которые как раз показывают реальное кол-во денег, которые можно потратить), каковы среднемесячные расходы, на сколько хватит текущего бюджета и тп. В те времена, когда у ХМ не было мобильного приложения, пробовала перейти на аналогичные сервисы, но не пошло из-за отсутствия виртуальных счетов. Так что ХМ остается для меня незаменимым помощником!
              .testimonial-arrow-down
              p.testimonial-author
                | Olga Malyuk
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Моя правая рука в деле финансовой стабильности
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | 1. Сервис постоянно развивается, учитываются пожелания пользователей, работает обратная связь.
                        br
                        |       2. Удобно.
                        br
                        |       3. Наглядная статистика.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Отсутствуют. Система постоянно развивается и совершенствуется, поэтому все недочеты исправляются.
                  p.review-content-text
                    | Все описал в плюсах и минусах. Пользуюсь постоянно, с появлением приложения стало еще удобнее вносить каждодневные расходы. Начав пользоваться системой, стал более серьезно относиться к учету и планированию финансов.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Ruslan Yarullin', src='https://lh4.googleusercontent.com/-KB4tsJ1T_6c/AAAAAAAAAAI/AAAAAAAADek/wdYQn9wUJiU/photo.jpg?sz=50')
                | Ruslan Yarullin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Ни дня без ХаниМани
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Абсолютно грамотная система ведения домашнего бюджета. Даже в ситуациях нерегулярного дохода программа помогает четко контролировать траты и никогда не оставаться на мели. Со временем можно научиться откладывать на цели и заветные желания.
                  p.review-content-text Ни дня без ХаниМани. Теперь для меня это так же естественно, как почистить зубы.
              .testimonial-arrow-down
              p.testimonial-author
                | Maria Chernoplechaya
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Не верили что сможем.
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p Просто, быстро, дружно. Online. Отчеты.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Долго ждали мобильного приложения.
                  p.review-content-text
                    | Жена не верила, что сможем вести домашнюю бухгалтерию в этой программе. Т.к. до этой программы пытались много раз, но всегда хватало на 2-3 месяца. На данный момент пользуемся почти 3 года. Я заношу расходы\доходы сразу, жена заносит раз в неделю, но у неё все через банковскую карту — делает выписки. Самое интересное это отчеты за разные периоды, можно посмотреть на что тратим больше и сделать выводы. Часто пользуемся календарем, чтобы посмотреть когда покупали тот или иной товар или уплачивали налог в этом году или нет. В этом году разработчик сделал мобильные приложения своей программы, за что ему огромное спасибо. Рекомендуем своим друзьям и коллегам.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Роман Селедков', src='https://graph.facebook.com/1155875517811158/picture')
                | Роман Селедков
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани — вести домашнюю бухгалтерию очень просто!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Удобный интерфейс
                        br
                        |       Простота учета
                        br
                        |       Мобильное приложение
                        br
                        |       Достаточная статистика
                        br
                        |       Отзывчивость разработчика
                  p.review-content-text
                    | Пользуюсь ХаниМани уже более двух лет. Изначально подкупили простота и удобство сервиса, и за два года в этом плане ничего не поменялось. Для того, чтобы вести домашнюю бухгалтерию с ХаниМани не нужно обладать какими-то финансовыми знаниями — просто нужно регулярно вносить свои доходы и свои траты. Система позволяет вести накопления путем добавления финансовых целей, и очень приятно наблюдать, как растет бюджет на очередной отпуск:)
              .testimonial-arrow-down
              p.testimonial-author
                | Nikita Kolganov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Ростовцев Сергей Николаевич
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Сервис очень понятен, прост в обращении, мне очень нравятся графики, очень наглядно и понятно определить все финансовые потоки, пользуюсь ресурсом уже больше года.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Хотелось бы ещё увидеть инфу про кредиты.
                  p.review-content-text
                    | Я доволен ресурсом, рекомендую всем... много увидите утечек денег, которые могли бы сэкономить. Понятно, наглядно, легко, доступно, ничего лишнего, графики супер.
              .testimonial-arrow-down
              p.testimonial-author
                | Sergey Rostovtsev
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Три года контроля над финансами вместе с ХаниМани
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Очень хороший вводный курс позволяющий понять, как же на самом деле надо вести финансы
                        br
                        |       Супер простая в освоении
                        br
                        |       Изначально нет ничего лишнего
                        br
                        |       Внесение трат занимает у меня 1-3 минуты
                        br
                        |       Система развивается на протяжении всех 3 лет, которые я ей пользуюсь
                        br
                        |       Техподдержка отвечает ВСЕГДА
                        br
                        |       Хорошие инструменты прогнозирования денежных потоков
                        br
                        |       Очень удобный концепт ХАНИМАНИ (подробнее в вводном курсе)
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Хани мани на 100 (!!!) рублей дороже хранения бесплатной экзельки в бесплатном дропбоксе. Хотя возможно это даже плюс
                        br
                        |       Нет нормального мобильного приложения с возможностью внесения трат оффлайн
                  p.review-content-text
                    | До этого довольно долго пользовался системой 4конверта, пока владелец не забросил её и не продал каким-то брокерам, которые всё окончательно доломали. В тот же момент встал вопрос, как же теперь вести финансы. Хотелось иметь доступ ото всюду, иметь довольно простой интерфейс и иметь какой-то уровень предсказания, позволяющий, без формул и изучения макроэкономики, понять сколько я могу потратить, сколько надо отложить, а когда наступит финансовый крах. Перепробовал тогда практически всё, что нашёл. В большинстве случаев остался недоволен слишком большой возможностью настроек. Да конечно интересно посмотреть в конце месяца сколько ты потратил на шоколадные сырки с изюмом, а сколько на шоколадные сырки с курагой. Но ведение всех этих систем превращается в пытку. ХаниМани подтолкнуло и научило меня вести учёт суперпростым способом, не слишком погрязая в мелочах и не слишком всё обобщая.
                    br
                    |     Итог: суперсбалансированная учётная система
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Dmitry Trukhanov', src='https://graph.facebook.com/10211019340745169/picture')
                | Dmitry Trukhanov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Максим и Люда
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Помогает в краткосрочном и долгосрочном финансовом планировании. Пользоваться удобно, всё интуитивно понятно и наглядно. Освоить можно очень быстро.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Неудобное мобильное приложение. Неудобно работать на планшете в андроиде.
                  p.review-content-text
                    | Начинали пользоваться 5 лет назад, когда влезли в ипотеку и приходилось экономить. За это время втянулись, доходы увеличились в разы, но продолжаем пользоваться. Спасибо!
              .testimonial-arrow-down
              p.testimonial-author
                | Lyudmila Kiselkova
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Использую более трех лет
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Использую систему ХаниМани уже более трех лет, нравится простота и легкость использования, симпатичный интерфейс, в этом году появилось мобильное приложение. Что радует: система развивается (спасибо Ильдару), появляются новые удобные фишки, отчетность понятна, быстродействие радует, ошибки исправляются, да, с доступностью сервиса проблем не испытывал. В общем, полностью доволен, и в том числе и тем, что есть возможность обратной связи с поставщиком программы, и твои пожелания учитываются для развития системы.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | При инвестициях (сбережениях) в валюту отслеживать позицию по доходу / расходу вследствие изменения курса не получится (во всяком случае у меня это не получилось), система конвертирует все валюты по своему курсу (видимо, центрального банка) в отличие от основной в основную, что не всегда правильно с точки зрения рынка. Общий тренд конечно по количеству денег понятен, но... На мой взгляд, может имеет смысл сделать механизм регулярной переоценки для продвинутых товарищей, кому нравится стандартный механизм — пусть используют свой, кто хочет отслеживать изменение курса с точки зрения дохода / расхода — пусть пользует ручной механизм (я могу сам например задать реперный курс и далее пересчитать, а что я в итоге выиграл или проиграл). Наверное это пожелание о введении нового вида счета — инвестиционного, на котором в принципе можно и всякие вещи связанные с ценными бумагами вести, почему бы и нет?
                  p.review-content-text
                    | Всем кто еще не использует систему учета средств, рекомендую это делать. И можно попробовать с ХаниМани, тем более, что есть бесплатный период. В общем, рекомендую.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Андрей Дмитриев', src='https://graph.facebook.com/1190960737633230/picture')
                | Андрей Дмитриев
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани — лучшая система для финансового планирования в семье
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Система завораживает своей дружелюбностью, удобством и интуитивностью. Живое общение с разработчиком, чрезвычайная его отзывчивость и заинтересованность никого не оставят равнодушным. У сервиса есть живое сообщество на форуме, и к чаяниям сообщества разработчик прислушивается, не очень давно добавил приложение для смартфонов на андроиде и iOS. Чтобы понять о чем я — достаточно попробовать, ведь один месяц для использования система бесплатна, а обучающие материалы (увлекательно написанные!) доступны вообще всегда :)
                  p.review-content-text Я пользуюсь сервисом не первый год, и не планирую уходить.
              .testimonial-arrow-down
              p.testimonial-author
                | Vasily Filatov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Очень удобное приложение
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p Лучший веб-интерфейс среди приложение по учету денег.
                  p.review-content-text
                    | Пользуюсь почти четыре года. Отличный легкий наглядный интерфейс.
                    br
                    |     Система построена для человека, который ей пользуется, а не вокруг учета и сложных форм.
                    br
                    |     Прямо на календаре вписываешь доходы и траты (прошлые и будущие) и я вижу когда сколько и на что я потратил. Вижу сколько денег мне придет, когда нужно оплатить ОСАГО и техобслуживание машины, купить подарок друзьям. Самое главное, деньги на это уже заложены в бюджет, если в предыдущей трате я это указал.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Timur Batyrshin', src='https://lh3.googleusercontent.com/-Ouu4ix_lZW0/AAAAAAAAAAI/AAAAAAAAAFg/3MUlo-DTabE/photo.jpg?sz=50')
                | Timur Batyrshin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани домашняя бухгалтерия
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Я уже 2 года с ХаниМани .Мне нравиться планировать свои доходы и расходы. Просчитывать покупки. Я вижу по категориям сколько на что я потратила. Могу посчитать динамику роста расходов на коммунальные услуги и на продукты питания.На строительство дома траты ( сколько ушло всего на стройку),на ремонт машины.,ремонт квартиры.Вижу динамику роста наших с мужем доходов.Проверяю,сверяю по SMS постоянно движение финансов по карте. Я всем советую ХаниМани , расходы небольшие в год и программа все сама просчитывает и выдает на план сетку.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Минус один. Нужно каждый день садиться за комп и писать расходы и доходы. Сначала это напрягает, а потом самой поскорее хочется это сделать и навести порядок в кошельке и на счетах сделать сверку. Это дело привычки.
                  p.review-content-text
                    | Молодцы , кто создал эту программу. Я планирую и в этом году продлить с ХаниМани сотрудничество.Дочь старшая тоже на меня глядя это делает.
              .testimonial-arrow-down
              p.testimonial-author
                | Lyudmila Semenova
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани — отличный инструмент!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Гибкость, прозрачность, возможность прогнозирования, автоматизированность процессов накопления
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Повышенные требования к себе — необходимо тщательно вносить все доходы и траты в систему.
                  p.review-content-text
                    | До 2014 года в семье существовала две проблемы — куда делись деньги и как дожить до зарплаты. После поиска и опробования нескольких программ по контролю семейного бюджета остановился на ХаниМани. Из всего, что я опробовал на тот момент, ХаниМани оказалась самой гибкой, понятной и прозрачной системой. Единственный минус — нужно приучать себя вносить все данные ежедневно, а иногда ежечасно. Теперь, независимо от уровня дохода всегда есть понимание того, что можешь себе позволить, что не можешь, на что дожить до зарплаты и на какие деньги обслуживать автомобиль. Простой пример: в семье весь ноябрь сплошные дни рождения со всеми вытекающими. До приобретения ХаниМани, каждый ноябрь, семья становилась банкротом. Теперь система в течении года резервирует средства под этот период и мы подходим к нему финансово подготовленными. В общем, очень рекомендую!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Аркадий Паровозов', src='https://graph.facebook.com/1112489058798520/picture')
                | Аркадий Паровозов
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Лучший сервис для планирования семейного бюджета
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | 1. Сервис не располагает к скрупулёзному ведению трат. Это можно делать, но очень легко без этого обойтись.
                        br
                        |       2. Очень просто начать планировать бюджет на год вперёд. Достаточно ввести данные о планируемых доходах и хотя бы о еженедельных тратах на продукты.
                        br
                        |       3. Очень отзывчивая человеческая поддержка.
                        br
                        |       4. Полностью функциональная версия в браузере. Не нужно ставить клиента.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Не возможно откатить выполненную случайно операцию. Приходится внимательно следить за транзакциями которые удаляешь, либо которые перемещаешь.
                  p.review-content-text Пользуюсь сервисом с 2012 года и альтернативы не ищу.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Александр Могильников', src='https://lh6.googleusercontent.com/-NejuOyd4Tzk/AAAAAAAAAAI/AAAAAAAAClU/C3ER3Dm-CQs/photo.jpg?sz=50')
                | Александр Могильников
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани лучшее средство ведения домашней бухгалтерии
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Отличный и дружественный интерфейс
                        br
                        |       Различные графики для анализа
                        br
                        |       Удобство добавления транзакций
                        br
                        |       Четкий контроль
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Есть мобильное приложение, но немного не удобное
                  p.review-content-text Лучшее приложение в своем классе
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Pavel Solovyev', src='https://media.licdn.com/mpr/mprx/0_0EnmcG_UUh203XIOpmGmJXSss6s0TB6tKRah-B9sOl9YTbz70yuhyvCsgh503v6OKRG_JP3VnrbOGP2f1JPt09h91rbxGPla1JPSqczR4At1H5EtxeLiN_RoP5g7wP60sscCP5n8MIz')
                | Pavel Solovyev
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='4')
                h3.review-content-name Удобный сервис для учета финансов
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | ХаниМани — удобный и простой сервис для домашней бухгалтерии. Нравится возможность учета в календарной сетке, возможность разделить счета на реальные и виртуальные. Есть мобильная версия.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Лично мне не хватает графической аналитики расходов, хочется большей наглядности...
                  p.review-content-text Пользуюсь уже больше 2х лет и планирую продолжать)))
              .testimonial-arrow-down
              p.testimonial-author
                | Anna Surina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Личные финансы — удобно и красиво
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Из отлично реализованного:
                        br
                        |       1. календарь трат
                        br
                        |       2. виртуальные счета
                        br
                        |       3. отчеты становятся всё интереснее
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Любые недочеты автор системы исправляет довольно оперативно. Минусов не вижу.
                  p.review-content-text
                    | Единственная система, которая даёт мне уверенность в знании, где находится кэш и на какие цели он распределен.
              .testimonial-arrow-down
              p.testimonial-author
                | Maxim Ivchenko
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Очень удобная система для планирования личного бюджета
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Простота и удобство как пользовательского интерфейса, так и непосредственно самой системы учёта денег. Минимальные затраты сил и времени на учёт финансов.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Значительных минусов не наблюдаю. Могу отметить, что система «заточена» на то, чтобы не считать копейки. Но если если есть желание их всё-таки считать, то это возможно, но чуть менее удобно (чаще приходится прописывать точные суммы в полях). Было бы лучше задавать в настройках два режима — с точным подсчётом, и с округлением.
                  p.review-content-text
                    | Пользуюсь ХаниМани уже около трёх лет. До этого несколько лет вёл личные финансы (и в экселе, и в других системах учёта) — постоянно что-то не устраивало, и возникало желание поменять подход. А в ХаниМани практически всё реализовано хорошо, все свои задачи по управлению финансами я легко решаю, необходимости что-либо менять не возникает.
              .testimonial-arrow-down
              p.testimonial-author
                | Arseny Gaevsky
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Прекрасный Money Keeper
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | ХаниМани позволяет сократить время на контроль семейного бюджета, есть абсолютное понимание сколько ты тратишь, сколько ты можешь тратить в этом месяце , следующем, или к какому времени можешь накопить на отпуск
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Все устраивает на данный момент. Все нужные для меня задачи ХаниМани выполняет.
                  p.review-content-text
                    | С началом семейной жизни приходит понимание и ответственность за семейный бюджет. Если бы не ХаниМани, пришлось бы заводить тетрадочку для учета денежных средств. Очень удобно реализована автоматизация учета денежных средств.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Anton Shelekhov', src='https://lh4.googleusercontent.com/-sj97jXN5iDg/AAAAAAAAAAI/AAAAAAAAADY/cozYFNKbC08/photo.jpg?sz=50')
                | Anton Shelekhov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Полезная привычка для осознанной жизни
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Календарь на месяц, удобно вводить данные;
                        br
                        |       просмотр текущего баланса доходов и расходов, в т.ч. наглядно в графике;
                        br
                        |       бюджеты и фонды, выделение средств на определенные статьи и контроль расходования средств.
                  p.review-content-text
                    | С добрыми пчелками "ХаниМани" дружу более 3-х лет. Легко и играючи, благодаря простоте и удобству программы, я привыкла вести учет и распределение семейных денег.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Tanya Bulgakova', src='https://graph.facebook.com/1127019010716757/picture')
                | Tanya Bulgakova
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name идеальная система учета моих денег
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | вносить данные легко, подсчеты (разных счетов, кошельков, доходов, расходов) ведутся прозрачно (по этой причине я отказалась от многих других сервисов — там черт ногу сломит "почему так посчиталось"), ОЧЕНЬ понятный и простой (и симпатичный!) интерфейс (не помню, было ли хоть раз, чтобы я что-то там не понимала)
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | не буду лукавить, что минусов нет, но они настолько незначительные, что приходится натужно вспоминать (чуть зависает, когда лезешь в дальние прошлые месяцы, или нельзя отредактировать трансакцию, если случайно сделал расход вместо дохода — нужно удалить или схитрить знаком минус), мобильное приложение пока сыровато, но задачу выполняет.
                  p.review-content-text
                    | Очень хорошая система учета финансов, пользуюсь много лет, всем советую, цена адекватная.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Ольга Галинская', src='https://lh6.googleusercontent.com/-fJhBkyaq0Cc/AAAAAAAAAAI/AAAAAAAAABs/HHPHbNRlHE0/photo.jpg?sz=50')
                | Ольга Галинская
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Отличный сервис для домашней бухгалтерии.
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Простота и удобство работы.
                        br
                        |       Наличие вэб-интефейса (для меня это критично), а с этого года и приложение для смартфонов.
                        br
                        |       Возможность понимать свои финансовые резервы во времени, что позволяет решать различные финансовые вопросы.
                        br
                        |       Возможность автоматического накопления на свои цели.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | 1. Стиль (концепция) сайта. Я понимаю, что для создателя он исторически очень важен, но выглядит всё как-то по-детски. Хочется иметь возможность выбора интерфейса хотя-бы. Нужно быть клиент-ориентированным и прислушиваться к мнению пользователей (я знаю, что не я один такой).
                        br
                        |       2. Нет возможности создавать счета с автоматическим начислением дохода (для депозитов, например). Понятно, что можно и вручную транзакции вводить, но всё-же...
                        br
                        |       3. Нет возможности добавлять категории счетов.
                  p.review-content-text
                    | У всех свои вкусы, но перепробовав основные программы для ведения домашней бухгалтерии, я остановился на ХаниМани, и спустя год не жалею об этом. Ничего лишнего, всё удобно, просто и понятно.
                    br
                    |     Не хватает некоторого функционала (описал в минусах), но не для всех это важно.
                    br
                    |     В дальнейшем буду искать что-то более подходящее под мои запросы.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Алексей Дорохин', src='https://media.licdn.com/mpr/mprx/0_CV5yQJA5rmnq2ldZGgCfQUh6pS3N2qZZ8ZliQU1Miez51K74apvKHRbB-iTE71MN_JbCWpS78g2e')
                | Алексей Дорохин
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name ХаниМани как панацея от финансовых проблем :-)
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Очень крутой фишкой в ХаниМани является "виртуальные деньги". С помощью них можно чётко знать, что ты потратишь на нужные расходы, а остальное можно будет потратить на себя. Для меня это довольно весомая вещь, из-за которой я и начал пользоваться данным сервисом.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Основной недостаток: нужно вводить все транзакции вручную. Это довольно скучно и утомительно.
                        br
                        |       Также в мобильной версии приложения нет календаря, как это есть в полной версии.
                  p.review-content-text Сам пользуюсь и буду пользоваться. В целом очень доволен. Рекомендую.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='SanyStation Alex', src='https://lh5.googleusercontent.com/-CUI-Qpb6SDU/AAAAAAAAAAI/AAAAAAAAACg/wxDI8cIS7no/photo.jpg?sz=50')
                | SanyStation Alex
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Великолепная система
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Позволяет прозрачно и понятно управлять деньгами
                        br
                        |       Возможность постановки целей
                        br
                        |       Удобное финансовое планирование
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Скорее не минусы, а пожелания:
                        br
                        |       1) открыть API
                        br
                        |       2) анализ SMS для автоматического занесения данных о расходах/доходах
                  p.review-content-text Пожалуй, самая удобная и ненавязчивая система для управления финансами
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Сергей Лахтарин', src='https://lh4.googleusercontent.com/-GggUOcRzBEo/AAAAAAAAAAI/AAAAAAAAABU/2-z-MXoaW4E/photo.jpg?sz=50')
                | Сергей Лахтарин
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Лучший сервис для управления личными финансами
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Главное отличие сервиса ХаниМани от остальных подобных систем в том, что у него есть идея разделения всех активов на виртуальные "кучки" (ХаниМани, Резервы, Сбережения). За счёт этого видно сколько денег нужно иметь в запасе для будущих расходов, а сколько можно спокойно тратить.
                        | br
                        | | Планировать бюджет очень удобно и быстро с помощью интерактивного календаря.
                        | br
                        | | Сервис позволяет создавать цели и автоматически накапливать на них.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | В сервисе не поддерживается работа с копейками. Это не сколько минус, сколько мизерное неудобство. Хотя такова идеология сервиса.
                        br
                        | Сервис не подходит для людей с высоким уровнем дохода, ведущих инвестиционную деятельность. Нет возможности управлять кредитами, депозитами, и т.п.
                  p.review-content-text
                    | Перепробовал много онлайн и десктоп сервисов, в том числе и англоязычных. Но ничего достойнее ХаниМани не нашёл. Уже более 2-х лет я с ХаниМани и очень доволен. Сервис активно развивается и поддерживается, в отличие от многих других.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Дмитрий Шамонов', src='https://graph.facebook.com/1746989678898362/picture')
                | Дмитрий Шамонов
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Ханимани и для бизнеса тоже
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | • Удобное планирование расходов и доходов и их управление цепочкой или по одному
                        br
                        |       • Календарь
                        br
                        |       • Наглядная отчетность
                        br
                        |       • Фильтры
                        br
                        |       • Статистика по категориям
                        br
                        |       • График прогнозов
                        br
                        |       • Создание целей и сбережений
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | • нельзя откладывать сбережения в процентном отношении от чистого дохода, но это скорее хотелка чем минус
                  p.review-content-text
                    | Очень простой и удобный сервис. Использую как бухгалтерию для дома и для бизнеса.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Stanislav Shangin', src='https://graph.facebook.com/10205370291456614/picture')
                | Stanislav Shangin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Хани-мани — просто и понятно
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p См. отзыв
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p См. отзыв
                  p.review-content-text
                    | Хани-мани — очень удобная и наглядная система для тех, кто хочет вести собственную бухгалтерию. Из плюсов можно отметить стабильность работы, наглядность, отсутствие бюрократизма. Из минусов — отсутствие возможности детализировать счета, также система вряд ли подойдет в чистом виде для семейной бухгалтерии или бухгалтерии малого бизнеса. Впрочем, система гибкая, и пользователи при желании смогут ее приспособить под любые нужды.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Peter Krasov', src='https://graph.facebook.com/10210774542179692/picture')
                | Peter Krasov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Луший сервис, которой поможет вам упорядочить свои расходы и доходы
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | — Календарный вид на месяц расходов/доходов без лишнего
                        br
                        |       — Мобильное приложение
                        br
                        |       — Скорость ответа поддержки
                        br
                        |       — Контроль будущих расходов с горизонтом в год, удобное вычисление разрывов
                        br
                        |       — Экспорт всех транзакций у Excel
                        br
                        |       — Много материалов по систематизации расходов
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p — отсутствует интеграция с банками (хотя бы было бы со сбером)
                  p.review-content-text
                    | Все расходы/доходы хорошо видно на календаре. есть удобный драг энд дроп.
                    br
                    |     Всегда видишь, сколько есть денег и когда они кончатся.
                    br
                    |     Очень удобно планировать регулярные траты как каждодневные, так и ежегодные.
                    br
                    |     Использую уже 3ий год. До этого за 3 месяца попробовал более 5ти систем.
                    br
                    |     Это первый и единственный сервис, за который я ежегодно плачу: считаю это главной оценкой.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Леонид Жук', src='https://lh4.googleusercontent.com/-YSy88mP6P7Y/AAAAAAAAAAI/AAAAAAAAALQ/yQyiu1ZAqBs/photo.jpg?sz=50')
                | Леонид Жук
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name годнота!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Позволяет очень точно вести учет и планирование, всегда видишь сколько денег у тебя есть, сколько нужно чтобы прожить, и т.д. всё очень грамотно и удобно.
                  p.review-content-text Пользуюсь уже не первый год, очень нравится!
              .testimonial-arrow-down
              p.testimonial-author
                | Dmitry Ushakov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Знаю сколько могу потратить
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Возможность нерегулярного ведения учета
                        br
                        |       Скорость сервиса
                        br
                        |       Оперативная поддержка.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Не обнаружил
                  p.review-content-text
                    | Для меня, довольно таки ленивого человека, который все равно хочет держать финансы под контролем, главным фактором оказалось возможность "нестрогого" учета расходов. После внесения всех регулярных доходов и расходов, формируется "свободный остаток" , который в сервисе называется "Ханимани". Нерегулярные расходы вношу раз в неделю и смотрю, сколько у меня еще ханимани и прикидываю, сколько могу потратить на этой неделе.
                    br
                    br
                    |     Пользуюсь сервисом уже более двух лет и позволил себе накопления, практически не меня образа жизни. Раньше же жил от зарплаты до зарплаты.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Vyacheslav Root', src='https://lh4.googleusercontent.com/-B82rtGvah9s/AAAAAAAAAAI/AAAAAAAAAp4/kNrkoLS60qI/photo.jpg?sz=50')
                | Vyacheslav Root
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='4')
                h3.review-content-name Honey Money легка в освоении)
                .review-content-body
                  p.review-content-text
                    | Перепробовал много различных систем, но в итоге остановился именно на Honey Money.
                    br
                    |     Все очень просто и понятно. Нет "громоздкости" других программ.
                    br
                    |     В конце каждого месяца сажусь и планирую доходы/расходы на следующий месяц. Выходит очень наглядно.
                    br
                    |     Отчеты очень хорошо сделаны. Есть мобильное приложение.
              .testimonial-arrow-down
              p.testimonial-author
                | Anton Tyurin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Идельный сервис учета и планирования фин средств
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p Низкий порог входа (интуитивно понятный UI, хорошая справка)
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Мобильная версия все-таки менее функциональна чем у аналогов
                        br
                        |       Отсутствует парсинг sms нотификаций о платежах
                  p.review-content-text
                    | Пользуюсь совместно с женой более 3-х лет. ХМ помог начать планировать расходы и вести подробный учет совершенных платежей с возможностью последующего анализа.
              .testimonial-arrow-down
              p.testimonial-author
                | Dmitry Lubnevsky
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Лучший сервис учета личных финансов!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | Функциональность, простота, отличный дизайн, скорость работы, удобные и красивые графики отчетов, наглядность всей информации. Также очень нравится подход к распределению финансов — учень удобная фишка с категорией ХаниМани! Отдельный плюс — это отзывчивость и открытость разработчика. Также к плюсам можно отнести наличие мобильных приложений.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p
                        | Минусов как таковых нету. По началу немного может быть сложно адаптировать систему под себя: нужно продумать как завести все свои счета, что и откуда будет потом тратится и куда зачислятся... Но потратив совсем немного времени, начинаешь понимать, что и куда:)
                  p.review-content-text
                    | В общей сложности я пользуюсь ХаниМани уже около полутора лет. До этого было как минимум 5 попыток ведения учета финансов, но максимум меня хватало на неделю. Оказывается вести учет и планировать бюджет можно легко, при этом каким-то чудесным образом начинают появлятся "свободные деньги", которые можно потратить на что-то полезное или откладывать на большую покупку. В общем можно рассказывать очень долго, но лучше взять и попробовать, тем более, что первый месяц бесплатно. Однозначно рекомендую!!!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Yaroslav Yakubovich', src='https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50')
                | Yaroslav Yakubovich
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Средства под контролем
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | + подсказки и разъяснения, с возможностью повторного их вызова
                        br
                        |       + простой удобный интерфейс
                        br
                        |       + хорошо продуманная система планирования средств
                        br
                        |       + очень удобный прямоугольный график расходов по категориям
                        br
                        |       + удобный просмотр статистики за произвольные периоды
                        br
                        |       + смежные сервисы и энциклопедии
                        br
                        |       + великолепная тех-поддержка
                        br
                        |       + удобное мобильное приложение
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p не нашел
                  p.review-content-text
                    | Всем привет. Пользуюсь сервисом «ХаниМани» уже больше года. Первое свое впечатление я получил в пробный период, когда в первые же 3 дня хани-мани поразил меня легкостью внесения данных и просмотром статистики по ним. Сразу же меня привлек квадратичный график с категориями, который невероятно наглядно отображает доходы и расходы. Второе приятно-положительное впечатление оставляет общение с непосредственным главой проекта живое и теплое. Да и вообще теплая ламповая атмосфера на форуме, в интерфейсе, приятный фирменный стиль и своя уникальная философия.
                    br
                    | Я не пожалел ни одного рубля, который внес на счет этого проекта, т.к. он шикарен, и деньги — это скорее благодарность создателям, нежели ежемесячный платеж.
              .testimonial-arrow-down
              p.testimonial-author
                | Vasily Demin
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Наводит порядок в голове и даёт чувство контроля над своими финансами
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Плюсы:
                    .column
                      p
                        | — Доступна аппликация на телефон (Android / iOS) и онлайн версия.
                        br
                        |       — Очень гибкая система — легко настраивается индивидуально
                        br
                        |       — Доступны отчёты по категориям
                        br
                        |       — С этой системой невозможно залезть в долги. Зато можно от них избавиться.
                        br
                        |       — Есть возможность ставить финансовые цели
                        br
                        |       — Можно вводить повторяющиеся транзакции
                        br
                        |       — Очень приятный интерфейс
                        br
                        |       — Отличная поддержка — отвечают на все вопросы и учитываются пожелания пользователей в новых обновлениях
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Минусы:
                    .column
                      p Жаль, что не нашел систему два года назад (наверное плохо рекламировалась)
                  p.review-content-text
                    | Пользовался разными системами, но остановился на ХаниМани ровно год назад.
                    br
                    |     Не собираюсь никуда уходить, очень доволен.
                    br
                    |     Система грамотно объясняет, куда уходят деньги, куда стоит их отложить.
                    br
                    br
                    |     Появляется четкое ощущения контроля своих финансов, что для меня важно.
              .testimonial-arrow-down
              p.testimonial-author
                | Evgeny Avin

</template>
